export default {
  discord: 'https://discord.com/invite/bTz5EDYh9Z',
  documentation:
    'https://docs.streamlit.io/en/stable/deploy_streamlit_app.html',
  documentationMain: 'https://docs.streamlit.io/en/stable/',
  forTeamsMarketing: 'https://streamlit.io/for-teams',
  forum: 'https://discuss.streamlit.io/c/13',
  forumBasePath: 'https://discuss.streamlit.io',
  snowflakeSupportLink:
    'https://community.snowflake.com/s/article/How-to-submit-a-support-case-for-Streamlit-Community-Cloud',
  snowflakeCommunityForumLink:
    'https://docs.streamlit.io/knowledge-base/deploy/how-to-submit-a-support-case-for-streamlit-community-cloud',
  harRecordingKBArticle:
    'https://community.snowflake.com/s/article/How-to-generate-a-HAR-file-for-Web-Interface-Troubleshooting',
  snowflakeSupport: 'Snowflake Support', // TODO: this shouldn't be here STREAMLIT-4662
  resourceLimits:
    'https://docs.streamlit.io/streamlit-community-cloud/manage-your-app#app-resources-and-limits',
  memoryUsageBlog:
    'https://blog.streamlit.io/common-app-problems-resource-limits',
  memoryLeakReleaseNotes: 'https://blog.streamlit.io/1-1-0-release-notes/',
  cloudMarketing: 'https://streamlit.io/cloud',
  cloudPlans: 'https://streamlit.io/cloud#plans',
  cloudSignup: 'https://forms.streamlit.io/cloud-sign-up',
  sharingSignUp: 'https://streamlit.io/sharing-sign-up',
  termsOfUse: 'https://www.streamlit.io/sharing/terms-of-use',
  privacyPolicy: 'https://streamlit.io/privacy-policy',
  streamlitGithubAuthPage:
    'https://github.com/settings/connections/applications/06f8c147652718282386',
  githubSettingsEmail: 'https://github.com/settings/emails',
  githubLogout: 'https://github.com/logout',
  sampleApp: 'https://github.com/streamlit/streamlit-example',
  coreCommunicationAllowedMessageOrigins: [
    '*.demo.streamlit.app',
    '*.demo.streamlit.run',
    '*.head.streamlit.app',
    '*.head.streamlit.run',
    '*.staging.streamlit.app',
    '*.staging.streamlit.run',
    '*.streamlit.apptest',
    '*.streamlit.app',
    '*.streamlit.run',
    '*.streamlitapp.com',
    '*.streamlitapp.test',
    'apps-bench.streamlitusercontent.com',
    'apps-demo.streamlitusercontent.com',
    'apps-devel.streamlitusercontent.test',
    'apps-head.streamlitusercontent.com',
    'apps-staging.streamlitusercontent.com',
    'apps.streamlitusercontent.com',
  ],
  tomlSpec: 'https://toml.io/en/latest',
  secretsDocs:
    'https://docs.streamlit.io/streamlit-cloud/get-started/deploy-an-app/connect-to-data-sources/secrets-management',
  upgradeStVersionInstructions:
    'https://docs.streamlit.io/knowledge-base/deploy/upgrade-streamlit-version-on-streamlit-cloud',
  resourceIncreaseForm:
    'https://share.hsforms.com/1DzDGAjUmSPy_2nUzBj3rlQ3wudj',
  termsAndAgreements1: 'https://streamlit.io/sharing/terms-of-use',
  termsAndAgreements2: 'https://streamlit.io/terms',
  githubCodespacesOverview: 'https://docs.github.com/en/codespaces/overview',
  githubCodespacesBilling:
    'https://docs.github.com/en/codespaces/overview#billing-for-codespaces',
  defaultGithubUserImageUrl:
    'https://storage.googleapis.com/s4a-prod-share-preview/default/default_github_user_logo.png',
  defaultAppFallbackImageUrl:
    'https://storage.googleapis.com/s4a-prod-share-preview/default/st_app_fallback_image_raw.png',
}
