const config = {
  content: [
    './index.html',
    './public/index.html',
    './src/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    extend: {
      textUnderlineOffset: {
        10: '10px',
      },
      boxShadow: {
        search: '0px 0px 0px 4px',
      },
      padding: {
        18: '72px',
      },
      screens: {
        '1.5xl': '1337px',
        '786px': '786px',
        '3xl': '1900px',
        '4xl': '2150px',
        '5xl': '2650px',
        '6xl': '3400px',
      },
      lineHeight: {
        'extra-loose': '5.625rem',
        14: '3.5rem',
      },
      opacity: {
        90: '0.9',
      },
      fill: {
        white: '#FFFFFF',
        transparent: 'transparent',
        // colors are taken from https://github.com/streamlit/streamlit/blob/develop/frontend/src/theme/primitives/colors.ts
        // 10 maps to 100 on above github link
        red: {
          100: '#fff0f0',
          200: '#ffdede',
          300: '#ffc7c7',
          400: '#ffabab',
          500: '#ff8c8c',
          600: '#ff6c6c',
          700: '#ff4b4b',
          800: '#ff2b2b',
          900: '#bd4043',
          1000: '#7d353b',
        },
        orange: {
          100: '#fffae8',
          200: '#fff6d0',
          300: '#ffecb0',
          400: '#ffe08e',
          500: '#ffd16a',
          600: '#ffbd45',
          650: '#f6ad55',
          700: '#ffa421',
          800: '#ff8700',
          900: '#ed6f13',
          1000: '#d95a00',
        },
        yellow: {
          100: '#ffffe1',
          200: '#ffffc2',
          300: '#ffffa0',
          400: '#ffff7d',
          500: '#ffff59',
          600: '#fff835',
          700: '#ffe312',
          800: '#faca2b',
          900: '#edbb16',
          1000: '#dea816',
        },
        green: {
          100: '#dffde9',
          200: '#c0fcd3',
          300: '#9ef6bb',
          400: '#7defa1',
          500: '#5ce488',
          600: '#3dd56d',
          700: '#21c354',
          800: '#09ab3b',
          900: '#158237',
          1000: '#177233',
        },
        bluegreen: {
          100: '#dcfffb',
          200: '#bafff7',
          300: '#93ffee',
          400: '#6bfde3',
          500: '#45f4d5',
          600: '#20e7c5',
          700: '#00d4b1',
          800: '#29b09d',
          900: '#2c867c',
          1000: '#246e69',
        },
        lightblue: {
          100: '#e0feff',
          200: '#bffdff',
          300: '#9af8ff',
          400: '#73efff',
          500: '#4be4ff',
          600: '#24d4ff',
          700: '#00c0f2',
          800: '#00a4d4',
          900: '#0d8cb5',
          1000: '#15799e',
        },
        blue: {
          100: '#e4f5ff',
          200: '#c7ebff',
          300: '#a6dcff',
          400: '#83c9ff',
          500: '#60b4ff',
          600: '#3d9df3',
          700: '#1c83e1',
          800: '#0068c9',
          900: '#0054a3',
          1000: '#004280',
        },
        violet: {
          100: '#f5ebff',
          200: '#ebd6ff',
          300: '#dbbbff',
          400: '#c89dff',
          500: '#b27eff',
          600: '#9a5dff',
          700: '#803df5',
          800: '#6d3fc0',
          900: '#583f84',
          1000: '#3f3163',
        },
        black: {
          100: '#fafafa',
          200: '#f0f2f6',
          300: '#e6eaf1',
          400: '#d5dae5',
          500: '#bfc5d3',
          600: '#a3a8b8',
          700: '#808495',
          800: '#555867',
          900: '#262730',
          1000: '#0e1117',
        },
        gray: {
          50: '#fafafa',
          100: '#f0f2f6',
          200: '#e6eaf1',
          300: '#d5dae5',
          400: '#bfc5d3',
          500: '#a3a8b8',
          600: '#808495',
          700: '#555867',
          750: '#31333F',
          800: '#262730',
          900: '#0e1117',
        },
      },
      fontFamily: {
        mono: ['IBM Plex Mono', 'monospace'],
        sans: ['Source Sans Pro', 'sans-serif'],
        code: ['Source Code Pro', 'monospace'],
      },
      fontSize: {
        tiny: '.813rem',
        '3/4': '0.75rem',
        '5xl': '3.38rem',
        '6.5xl': '4rem',
        '5.25rem': '5.25rem',
        '3.5rem': '3.5rem',
      },
      width: {
        0.1: '10%',
        0.6: '60%',
        10.5: '2.625rem',
        38: '9.5rem',
        650: '650px',
        680: '680px',
        385: '385px',
        120: '480px',
        132: '33rem',
      },
      minWidth: {
        0: '0',
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
        '13rem': '13rem',
        40: '10rem',
        48: '12rem',
        full: '100%',
        min: 'min-content',
      },
      maxWidth: {
        '3.5xl': '51.5rem',
        '78%': '78%',
        310: '1240px',
        full: '100%',
        max: 'max-content',
      },
      margin: {
        28: '7rem',
        3.5: '0.875rem',
        18: '4.5rem',
        22: '1.375rem',
      },
      height: {
        9: '2.25rem',
        22: '5.5rem',
        100: '25rem',
        50: '12.625rem',
        10.5: '2.625rem',
      },
    },
    container: {
      padding: '2rem',
    },
    colors: {
      current: 'currentColor',
      white: '#FFFFFF',
      transparent: 'transparent',
      red: {
        100: '#fff0f0',
        200: '#ffdede',
        300: '#ffc7c7',
        400: '#ffabab',
        500: '#ff8c8c',
        600: '#ff6c6c',
        700: '#ff4b4b',
        800: '#ff2b2b',
        900: '#bd4043',
        1000: '#7d353b',
      },
      orange: {
        100: '#fffae8',
        200: '#fff6d0',
        300: '#ffecb0',
        400: '#ffe08e',
        500: '#ffd16a',
        600: '#ffbd45',
        650: '#f6ad55',
        700: '#ffa421',
        800: '#ff8700',
        900: '#ed6f13',
        1000: '#d95a00',
      },
      yellow: {
        100: '#ffffe1',
        200: '#ffffc2',
        300: '#ffffa0',
        400: '#ffff7d',
        500: '#ffff59',
        600: '#fff835',
        700: '#ffe312',
        800: '#faca2b',
        900: '#edbb16',
        1000: '#dea816',
      },
      green: {
        100: '#dffde9',
        200: '#c0fcd3',
        300: '#9ef6bb',
        400: '#7defa1',
        500: '#5ce488',
        600: '#3dd56d',
        700: '#21c354',
        800: '#09ab3b',
        900: '#158237',
        1000: '#177233',
      },
      bluegreen: {
        100: '#dcfffb',
        200: '#bafff7',
        300: '#93ffee',
        400: '#6bfde3',
        500: '#45f4d5',
        600: '#20e7c5',
        700: '#00d4b1',
        800: '#29b09d',
        900: '#2c867c',
        1000: '#246e69',
      },
      lightblue: {
        100: '#e0feff',
        200: '#bffdff',
        300: '#9af8ff',
        400: '#73efff',
        500: '#4be4ff',
        600: '#24d4ff',
        700: '#00c0f2',
        800: '#00a4d4',
        900: '#0d8cb5',
        1000: '#15799e',
      },
      blue: {
        100: '#e4f5ff',
        200: '#c7ebff',
        300: '#a6dcff',
        400: '#83c9ff',
        500: '#60b4ff',
        600: '#3d9df3',
        700: '#1c83e1',
        800: '#0068c9',
        900: '#0054a3',
        1000: '#004280',
      },
      violet: {
        100: '#f5ebff',
        200: '#ebd6ff',
        300: '#dbbbff',
        400: '#c89dff',
        500: '#b27eff',
        600: '#9a5dff',
        700: '#803df5',
        800: '#6d3fc0',
        900: '#583f84',
        1000: '#3f3163',
      },
      black: {
        100: '#fafafa',
        200: '#f0f2f6',
        300: '#e6eaf1',
        400: '#d5dae5',
        500: '#bfc5d3',
        600: '#a3a8b8',
        700: '#808495',
        800: '#555867',
        900: '#262730',
        1000: '#0e1117',
      },
      gray: {
        50: '#fafafa',
        100: '#f0f2f6',
        200: '#e6eaf1',
        300: '#d5dae5',
        400: '#bfc5d3',
        500: '#a3a8b8',
        600: '#808495',
        700: '#555867',
        750: '#31333F',
        800: '#262730',
        900: '#0e1117',
      },
    },
  },
}

export default config
export const theme = config.theme
