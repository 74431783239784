import './init'

import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import './index.css'
import { initDatadog } from './telemetry/datadog'
import { initAxios } from 'api/axios'

initDatadog()

initAxios()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
